export enum PAGES {
  ROOT = "/",
  LOGIN = "/login",
  NOT_FOUND = "*",
}

type ParsedSearchParams = Record<string, string>;

export type Scheme<Endpoints extends PAGES = PAGES> = {
  scheme: Endpoints;
  params?: Record<string, string>;
  getParams?: ParsedSearchParams;
};

export type FeScheme = Scheme<PAGES> | [Scheme<PAGES>];
