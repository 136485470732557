import "dayjs/locale/en";
import "dayjs/locale/ru";

import dayjs, { Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { MenuDay } from "src/_generated";

export { Dayjs } from "dayjs";

dayjs.extend(localeData);

export const dt = dayjs;

export enum FORMAT {
  DEFAULT = "DD.MM.YYYY",
  DATETIME = "DD.MM.YYYY HH:mm",
  TIME = "HH:mm",
}

export const changeLocale = (locale: string) => {
  dayjs.locale(locale)
};

export const isValid = (date?: string | number): boolean =>
  date ? dayjs(date).isValid() : false;

export const format = (
  date?: string,
  format: FORMAT = FORMAT.DEFAULT
): string | null => (isValid(date) ? dayjs(date).format(format) : null);

export const currentYear = new Date().getFullYear();



export const findNearestDayIndex = (days: MenuDay[]) => {
  const yesterday = getToday().subtract(1, 'day')

  return days.findIndex(day =>
    dayjs.unix(day.timestamp).isAfter(yesterday, 'day'),
  )
}


export const getToday = () => dayjs();

export const getDeliveryDate = (day: MenuDay): Dayjs => {
  const date = dayjs.unix(day.timestamp)
  const weekDay = date.toDate().getDay();

  const deliveryDayDiff = [1, 3, 5].includes(weekDay) ? -1 : -2;

  return date.add(deliveryDayDiff, 'day')
}

const DAYS_BEFORE_DELIVERY = 5

export const getDishChangeAllowed = (menuDay: MenuDay) => {
  const date = dayjs.unix(menuDay.timestamp)

  const today = getToday()

  const deliveryDate = getDeliveryDate(menuDay)

  const diff = deliveryDate.diff(today, 'day')

  return date.isAfter(today) && diff >= DAYS_BEFORE_DELIVERY
}


