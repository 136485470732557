import { QueryClient } from "@tanstack/react-query";
import { Exception, MenuDay, MenuResponse } from "src/_generated";
import { Locales } from "src/i18n/types";

export enum MODELS {
  LOCALE = "locale",
  AUTH_TOKEN = "auth_token",
  MENU = "menu",
  CUSTOMER_EXPECTIONS = "customer_expections",
  EXPECTIONS = "expections",
}

export const store = {
  get: <T>(client: QueryClient, model: MODELS) =>
    client.getQueryData<T>([model]),
  locale: (client: QueryClient, data: Locales) => {
    client.setQueryData([MODELS.LOCALE], () => data);
  },
  authToken: (client: QueryClient, data: string) => {
    client.setQueryData([MODELS.AUTH_TOKEN], () => data);
  },
  menu: (client: QueryClient, data: MenuResponse) => {
    client.setQueryData([MODELS.MENU], () => data);
  },
  customerExpections: (client: QueryClient, data: string[]) => {
    client.setQueryData([MODELS.CUSTOMER_EXPECTIONS], () => data);
  },
  expections: (client: QueryClient, data: Exception) => {
    client.setQueryData([MODELS.EXPECTIONS], () => data);
  },
  clear: (client: QueryClient) => client.clear(),
} as const;
